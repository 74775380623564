<template>
  <el-row style="width: 170px">
    <el-col :class="$langConfig.cssClass" style="width: 100%">
      <dg-text-field
          :control="control" :editable="control.editable" :disabled="control.ctrlsDisabled"
          :editMode="editMode"
          :field_span="16"
          :label_span="7"
          :translateLabel='false'
          field_mark="required"
          label="NEQ"
          placeholder="xxxxxxxxxx"
          maxLength="10"
          name="tax_neq"
          rules="required"></dg-text-field>
    </el-col>
    <!--<el-col :class="$langConfig.cssClass" style="width: 100%">
      <dg-text-field
          :control="control" :editable="control.editable" :disabled="control.ctrlsDisabled"
          :editMode="editMode"
          :field_span="16"
          :label_span="7"
          :translateLabel='false'
          field_mark="required"
          label="NEQ"
          placeholder="xxx-xxx-xxxx"
          maxLength="10"
          :liveUpdate="true"
          name="tax_neq"
          @amUpdated="part1Updated"
          rules="required"></dg-text-field>
    </el-col>-->
  </el-row>
</template>

<script>
export default {
  methods: {
    mask (value) {
      let output = [];
      for(let i = 0; i < value.length; i++) {
        if(i !== 0 && i % 3 === 0) {
          output.push("-"); // add the separator
        }
        output.push(value[i]);
      }
      return output.join("")
    },
    unmask (val) {
      return val.replace(new RegExp(/[^\d]/, 'g'), ''); // Remove every non-digit character
    },
    part1Updated (props) {
      let value = props.field.field_value
      value = this.unmask(value)
      props.field.field_value =  this.mask(value);
    }
  },
  props: {
    control: {
      required :true
    },
    editMode: {
      required :true
    }
  }
}
</script>
